//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import { UserModel } from '@/modules/auth/user-model';
// import { i18n } from '@/i18n';
import PrettyCheck from 'pretty-checkbox-vue/check';
import Vue from 'vue';
Vue.component('p-check', PrettyCheck);
const { fields } = UserModel;

export default {
  name: 'app-signin-page',

  data() {
    return {
      rules: {
        email: fields.email.forFormRules(),
        password: fields.password.forFormRules(),
        rememberMe: fields.rememberMe.forFormRules(),
      },
      model: {
        rememberMe: true,
      },

      inputColor: '#E2FFFF',
      isPwd: true,
    };
  },

  computed: {
    // mapGetters to return loading

    ...mapGetters({
      loading: 'auth/loading',
      loadingGoogle: 'auth/loadingGoogle',
      loadingFacebook: 'auth/loadingFacebook',
      loadingTwitter: 'auth/loadingTwitter',
      currentLanguageCode: 'layout/currentLanguageCode',
      is_screen_xs: 'layout/is_screen_xs',
    }),

    fields() {
      return fields;
    },

    // this computed for enableRtl

    isRTL() {
      // return localStorage.getItem('language') == 'ar'
      return this.currentLanguageCode == 'ar';
    },
  },

  methods: {
    prev(e) {
      e.preventDefault();
    },

    // mapAction call to two function from store { doSigninSocial && doSigninWithEmailAndPassword }

    ...mapActions({
      doSigninSocial: 'auth/doSigninSocial',
      doSigninWithEmailAndPassword:
        'auth/doSigninWithEmailAndPassword',
    }),

    // submitting form of sign in , first check form validation then catch error if exist , finally awiat for for function
    // doSigninWithEmailAndPassword  with email and password that defined already in mapActions

    async doSubmit() {
      try {
        await this.$refs.form.validate();
      } catch (error) {
        return;
      }

      await this.doSigninWithEmailAndPassword({
        email: this.model.email,
        password: this.model.password,
        rememberMe: this.model.rememberMe,
      });
    },

    // i18n for localization

    i18n(key, args) {
      // return i18n(code);
      return this.$t(key, args);
    },
  },
};
